import React, { useContext } from 'react';
import Gallery from '../Gallery';
import { Footer } from '../layout/Footer';
import { PetType, PhotoCardMedia, SearchFilter } from '@prp/shared';
import { LocationContext } from '../../state/LocationContext';
import { FilterContext } from '../../state/FilterContext';
import { MOHAWK_HUDSON } from '../utils/Location';
import PetAttributesFilter from '../utils/PetAttributesFilter';
import { useInfiniteScrollQuery } from '../../hooks/useInfiniteScrollQuery';
import { client } from '../../utils/index';
import { useLocation } from 'react-router';
import AdoptedModal from './AdoptedModal';

type ComponentProps = {
  petType: PetType;
};

const Search: React.FC<ComponentProps> = ({ petType }) => {
  const { location } = useContext(LocationContext);
  const filterContext = useContext(FilterContext);

  const filter: SearchFilter = {
    ...filterContext.searchFilter,
  };

  const { data, isLoading, isFetchingNextPage, hasNextPage } =
    useInfiniteScrollQuery<{
      items: PhotoCardMedia[];
      nextCursor: string | undefined;
    }>(
      client.pets.getMediaMatches.useInfiniteQuery(
        {
          petType,
          location: location || MOHAWK_HUDSON,
          filter,
        },
        {
          getNextPageParam: (lastPage) => lastPage.nextCursor,
        },
      ),
      useLocation().pathname,
    );

  const media = data?.pages.map((group) => group.items).flat() || [];

  return (
    <div className="flex flex-col min-h-screen">
      <AdoptedModal petType={petType} />
      <PetAttributesFilter filter={filter} />
      <div className="flex flex-grow pt-12 px-4">
        <Gallery
          media={media}
          isLoading={isLoading || isFetchingNextPage}
          hasNextPage={hasNextPage}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Search;
