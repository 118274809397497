import React from 'react';
import { stopwords } from '../../utils/stopwords';
import {
  LIGHT_GRAY,
  SECONDARY_COLOR,
  nanoidToNumberInRange,
} from '../../utils/index';

export interface OrgProfilePhotoProps {
  orgName: string;
  orgId: string;
  isSelected?: boolean;
  handleClick?: (orgId: string) => void;
  isDisabled?: boolean;
}

export const getBackgroundColor = (orgId: string): string => {
  const hue = nanoidToNumberInRange(orgId, 0, 360);
  const saturation = nanoidToNumberInRange(orgId, 40, 80);
  const lightness = nanoidToNumberInRange(orgId, 30, 70);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const getAcronym = (organizationName: string): string => {
  return organizationName
    .replace(/[^\w\s]|_/g, ' ') // Remove any punctuation or special characters
    .split(/\s+/) // Split the string by spaces into an array of words
    .map((word) => word.toLowerCase())
    .filter((word) => !stopwords.has(word))
    .map((word) => word[0]?.toUpperCase()) // Get the first letter of each word and convert to uppercase
    .join('') // Join the letters together to form the acronym
    .slice(0, 4); // Limit the acronym to 4 characters
};

const OrgIcon: React.FC<OrgProfilePhotoProps> = ({
  orgName,
  orgId,
  isSelected = false,
  handleClick = () => {
    //no-op
  },
  isDisabled = false,
}) => {
  const acronym = getAcronym(orgName);
  const bgColor = getBackgroundColor(orgId);

  const color = isDisabled ? LIGHT_GRAY : 'white';
  const boarderColor = isSelected ? SECONDARY_COLOR : color;

  const style: React.CSSProperties = {
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: bgColor,
    borderRadius: '50%',
    width: '4rem',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: color,
    border: `6px solid ${boarderColor}`,
  };

  return (
    <div
      className="flex items-center justify-center"
      onClick={() => handleClick(orgId)}
    >
      <div style={style}>{acronym}</div>
    </div>
  );
};

export default OrgIcon;
