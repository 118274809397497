import React, { useContext, useEffect, useRef, useState } from 'react';
import { PetPortrait } from './PetPortrait';
import { PetType } from '@prp/shared';
import OrgIcon from '../support/OrgIcon';
import { client, nanoidToNumberInRange, titleCase } from '../../utils/index';
import { MOHAWK_HUDSON } from '../utils/Location';
import { LocationContext } from '../../state/LocationContext';
import { useLocalStorage } from 'usehooks-ts';

type AdoptedModalProps = {
  petType: PetType;
};

const AdoptedModal: React.FC<AdoptedModalProps> = ({ petType }) => {
  const modalRef = useRef<HTMLDialogElement>(null);
  const [catId, setCatId] = useLocalStorage<string>('AdoptedCatId', '');
  const [dogId, setDogId] = useLocalStorage<string>('AdoptedDogId', '');
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const { location } = useContext(LocationContext);
  const response = client.pets.getMostRecentlyAdoptedByLocation.useQuery({
    petType,
    location: location || MOHAWK_HUDSON,
  });

  useEffect(() => {
    if (response.data && imageLoaded) {
      //wait for adoption data and the image to have been loaded
      const adoptionInfo = response.data;
      const petId = petType === 'cat' ? catId : dogId;
      if (adoptionInfo.petId !== petId && modalRef.current) {
        //show modal if the petId has changed since last shown
        modalRef.current.showModal();
      }
    }
  }, [petType, catId, dogId, response.data, imageLoaded]);

  if (!response.data) {
    return null;
  }
  const adoptionInfo = response.data;
  const { petId, petName, orgId, orgName } = adoptionInfo;

  const handleClose = () => {
    if (modalRef.current) {
      modalRef.current.close();
    }
    petType === 'cat' ? setCatId(petId) : setDogId(petId);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  const formattedPetName = titleCase(petName);
  const text = [
    `Well done ${formattedPetName}, on your adoption from ${orgName}!`,
    `Great job ${formattedPetName}, on your adoption from ${orgName}!`,
    `Congrats ${formattedPetName}, on being adopted from ${orgName}!`,
    `Way to go ${formattedPetName}, on your adoption from ${orgName}!`,
    `Cheers to ${formattedPetName}, on being adopted from ${orgName}!`,
    `Bravo ${formattedPetName}, on your adoption from ${orgName}!`,
  ];
  const index = nanoidToNumberInRange(petId, 0, text.length - 1);
  const randomText = text[index];

  const petString = petType === 'cat' ? 'Cat' : 'Dog';

  if (!imageLoaded) {
    //preload image before displaying modal
    return (
      <PetPortrait
        petName={petName}
        profilePhotoUrl={adoptionInfo.photo}
        handleImageLoad={handleImageLoad}
        preload={true}
      />
    );
  }

  return (
    <dialog
      ref={modalRef}
      className="p-4 bg-light rounded shadow-lg w-96 md:w-1/2 max-w-2xl"
      onKeyDown={handleKeyDown}
    >
      <div className="flex-col align-center">
        <div className="flex bg-secondary p-2 ">
          <p className="text-3xl text-white">{randomText}</p>
          <div className="mx-2 hidden md:flex md:items-center">
            <OrgIcon orgId={orgId} orgName={orgName} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-around">
          <PetPortrait petName={petName} profilePhotoUrl={adoptionInfo.photo} />
        </div>
      </div>
      <button
        onClick={handleClose}
        className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-secondary"
      >
        Find My {petString}
      </button>
    </dialog>
  );
};

export default AdoptedModal;
