import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Footer } from '../layout/Footer';
import {
  client,
  createSafeURL,
  getFirstPhotoUrl,
  getTitle,
  PET_TYPE_KEY,
} from '../../utils';
import PetList from '../support/PetList';
import { useLocalStorage } from 'usehooks-ts';
import { PetType, SearchFilter } from '@prp/shared';
import PetAttributesFilter from '../utils/PetAttributesFilter';
import { FilterContext } from '../../state/FilterContext';
import PetTypeSelector from '../utils/PetTypeSelector';
import ContactOptions from '../support/ContactOptions';
import PageLabel from '../utils/PageLabel';
import LoadingPage from '../utils/LoadingPage';
import ExpandableText from '../utils/ExpandableText';

const ORG_IMAGE_WIDTH = 300;

type OrgParams = {
  orgId: string;
};


const isEmptyStringOrArray = (value: unknown): boolean => {
  return (
    (typeof value === "string" && value.trim() === "") || // Check for empty string
    (Array.isArray(value) && (value.length === 0 || value.join('').length === 0))         // Check for empty array
  );
};

export const OrgDetail: React.FC = () => {
  const { orgId } = useParams<OrgParams>();
  const [petType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'dog');

  const filterContext = useContext(FilterContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [petType]);

  const filter: SearchFilter = {
    ...filterContext.searchFilter,
  };

  if (!orgId) {
    return <div>Unknown Org</div>;
  }

  const detail = client.org.getOrganization.useQuery({ id: orgId });
  const org = detail.data;

  if (!org) {
    return <LoadingPage />;
  }

  const photoUrl = getFirstPhotoUrl(org);
  const urlProfile = photoUrl ? createSafeURL(photoUrl) : null;
  if (urlProfile) {
    urlProfile.searchParams.append('width', ORG_IMAGE_WIDTH.toString());
  }
  const imageUrl = urlProfile ? urlProfile.toString() : null;

  document.title = getTitle(org.name);

  const mission = !isEmptyStringOrArray(org.mission) ? org.mission : null;
  const orgText = !isEmptyStringOrArray(org.description)
    ? org.description
    : mission
      ? [mission]
      : null;

  const hasProfileContent = !!imageUrl || !!orgText;

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={org.name} indentRem={2} />
        </div>
        {hasProfileContent &&
        <div className="profile-content space-y-4">
          {imageUrl && (
            <div className="profile-image">
              <img src={imageUrl} alt={org.name} />
            </div>
          )}
          {orgText && <ExpandableText paragraphs={orgText} previewHeight={'medium'} />}
        </div>
        }
        <div className="flex flex-grow items-center justify-center">
          <ContactOptions org={org} />
        </div>
        <PetAttributesFilter filter={filter} />
        <PetTypeSelector />
        <div className="flex flex-grow items-center justify-center">
          <PetList orgId={orgId} petType={petType} filter={filter} />
        </div>
      </div>
      <Footer />
    </div>
  );
};
