import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router';
import ScrollWatcherInstance from '../utils/ScrollWatcher';
import { UNKNOWN_LOCATION } from '../utils/Location';
import { LocationContext } from '../../state/LocationContext';

import { FaMapMarkedAlt, FaCat } from 'react-icons/fa';
import { FaBoxArchive, FaPeopleRoof, FaDog } from 'react-icons/fa6';
import HamburgerMenu from './HamburgerMenu';

export const Navbar: React.FC = () => {
  const location = useLocation();
  const currentSearchParams = location.search;

  const locationContext = useContext(LocationContext);
  const locationName = locationContext?.location?.name;
  const postalCode = locationContext?.location?.postalCode;

  useEffect(() => {
    const scrollObserver = (change: number) => {
      const header = document.getElementById('navbar');
      if (header) {
        const { scrollTop, clientHeight } = document.documentElement;
        if (change > 0 && scrollTop > clientHeight / 2) {
          //scrolled down
          header.classList.remove('nav-down');
          header.classList.add('nav-up');
        } else if (change < 0) {
          //scrolled up
          header.classList.remove('nav-up');
          header.classList.add('nav-down');
        }
      }
    };
    ScrollWatcherInstance.addListener(scrollObserver);
    return () => {
      ScrollWatcherInstance.removeListener(scrollObserver);
    };
    // eslint-disable-next-line
  }, []);

  const changeLocation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    locationContext.setShowLocationModal(true);
    return false;
  };

  return (
    <nav id="navbar" className="bg-primary">
      <div className="nav-menu">
        <HamburgerMenu />

        <div>
          <a href="!#" onClick={changeLocation} className="flex items-center">
            <FaMapMarkedAlt className="h-6 w-6 mr-2" />{' '}
            <span id="location-name">{locationName || UNKNOWN_LOCATION}</span>
            <span id="location-postcode" className="menu-text">
              {postalCode}
            </span>
          </a>
        </div>

        <ul>
          <li>
            <Link
              to={`/cats${currentSearchParams}`}
              className="flex items-center"
            >
              <FaCat className="mr-2 h-6 w-6" />
              <span className="menu-text">Cats</span>
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link
              to={`/dogs${currentSearchParams}`}
              className="flex items-center"
            >
              <FaDog className="mr-2 h-6 w-6" />
              <span className="menu-text">Dogs</span>
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link
              to={`/organizations${currentSearchParams}`}
              className="flex items-center"
            >
              <FaPeopleRoof className="h-6 w-6 mr-2" />
              <span className="menu-text">Organizations</span>
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/resources" className="flex items-center">
              <FaBoxArchive className="mr-2 h-6 w-6" />
              <span className="menu-text">Resources</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
