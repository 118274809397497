import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { FaCat, FaDog } from 'react-icons/fa';
import config from '../../config';

const md = 768;

const Header: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= md);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageDomain = config.cdnUrl;
  const desktopImage = `${imageDomain}/showcase.webp`; //https://unsplash.com/photos/ouo1hbizWwo
  const mobileImage = `${imageDomain}/mobile-showcase.webp`; //https://unsplash.com/photos/Ivzo69e18nk
  const backgroundImage = isMobile ? mobileImage : desktopImage;

  const heroStyle: React.CSSProperties = {
    background: `url('${backgroundImage}') no-repeat center center/cover`,
    height: '100vh',
    position: 'relative',
    paddingTop: '4rem',
  };

  return (
    <div className="hero text-white" style={heroStyle}>
      <div className="absolute top-1/2 w-full text-xl">
        <div className="flex justify-around">
          <div className="button">
            <h1>
              <Link to="/cats">
                <button className="flex items-center bg-primary text-white px-4 py-2 rounded">
                  <FaCat className="mr-1" />
                  <span className="link-text"> Cats</span>
                </button>
              </Link>
            </h1>
          </div>
          <div className="button">
            <h1>
              <Link to="/dogs" className="flex items-center">
                <button className="flex items-center bg-primary text-white px-4 py-2 rounded">
                  <FaDog className="mr-1" />
                  <span className="link-text"> Dogs</span>
                </button>
              </Link>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
