import React, { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router';
import { useNavigationContext } from '../../state/NavigationContext';

const CustomScrollHandler: React.FC = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  const { getScrollPosition } = useNavigationContext();

  useEffect(() => {
    const scrollPosition = getScrollPosition(location.pathname);
    window.scrollTo(0, scrollPosition);
  }, [location, navigationType, getScrollPosition]);

  return null;
};

export default CustomScrollHandler;
