import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { SearchFilter } from '@prp/shared';
import { noop } from '../utils/index';

type ComponentProps = {
  children: React.ReactNode;
};

type FilterContextType = {
  searchFilter: SearchFilter;
  setSearchFilter: (newSearchFilter: SearchFilter) => void;
  // distance: number;
  // setDistance: (distance: number) => void;
};

export const isFilterSet = (filterObject: SearchFilter) => {
  for (const key in filterObject) {
    const value = filterObject[key as keyof SearchFilter];
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return true;
    }
    if (typeof value === 'boolean') {
      return true;
    }
  }
  return false;
};

export const FilterContext = createContext<FilterContextType>({
  searchFilter: {},
  setSearchFilter: noop,
  // distance: DEFAULT_DISTANCE,
  // setDistance: () => {},
});

const safeJSONParse = (jsonString: string, defaultValue = {}) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.warn('JSON parsing failed:', error);
    // Return defaultValue when parsing fails
    return defaultValue;
  }
};

export const FilterProvider: React.FC<ComponentProps> = ({ children }) => {
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({});
  // const [distance, setDistance] = useState<number>(DEFAULT_DISTANCE);

  const navigate = useNavigate();

  const updateSearchFilter = async (filterItems: SearchFilter) => {
    const searchParams = new URLSearchParams();
    searchParams.set(SEARCH_PARAM, btoa(JSON.stringify(filterItems)));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    await navigate(newUrl, { replace: true });
    setSearchFilter(filterItems);
  };

  const contextValue = {
    searchFilter,
    setSearchFilter: updateSearchFilter,
    // distance,
    // setDistance,
  };

  const SEARCH_PARAM = 'filter';

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get(SEARCH_PARAM);
    if (filterParam) {
      const urlState = safeJSONParse(decodeURIComponent(atob(filterParam)));
      setSearchFilter(urlState);
    }
  }, [location]);

  //   specialNeeds

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
