import React from 'react';
import { addressToString, Organization } from '@prp/shared';
import ExternalLink from '../utils/ExternalLink';
import {
  FaFacebook,
  FaXTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest,
} from 'react-icons/fa6';

interface ContactOptionsProps {
  org: Organization;
}

const ContactOptions: React.FC<ContactOptionsProps> = ({
                                                         org,
}) => {
  const socialMedia = org.socialMedia || {};
  const { facebook, instagram, youtube, twitter, pinterest } = socialMedia;
  const website = org.website;

  const destinationAddress = addressToString(org.address);
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destinationAddress)}&travelmode=driving`;

  const poBoxRegex = /\b(P\.?O\.?|Post Office)\s+Box\b/i;
  const isDrivable = !!org.address.address1 && !poBoxRegex.test(org.address.address1);

  const anySocial = facebook || instagram || youtube || twitter || pinterest;
  if (!anySocial && !website && !isDrivable) {
    return null;
  }

  return (
    <div className="bg-white py-2 md:py-4 px-4 flex-grow">
      <h1 className="text-3xl font-bold mt-2">Visit us at</h1>
      <div className="flex items-center justify-around flex-col md:flex-row mb-2">
        {isDrivable && (
          <ExternalLink href={googleMapsUrl} ariaLabel="Google Map Directions">
            <button className="w-48 px-4 py-2 m-2 text-white rounded text-2xl bg-primary hover:bg-dark">
              Directions
            </button>
          </ExternalLink>)}
        {anySocial && (
          <div className="flex items-center justify-center text-6xl my-2">
            {facebook && (
              <ExternalLink href={facebook} ariaLabel="Facebook">
                <FaFacebook className="m-1 text-blue-600 hover:text-blue-800" />
              </ExternalLink>
            )}
            {instagram && (
              <ExternalLink href={instagram} ariaLabel="Instagram">
                <FaInstagram className="m-1 text-pink-500 hover:text-pink-700" />
              </ExternalLink>
            )}
            {youtube && (
              <ExternalLink href={youtube} ariaLabel="YouTube">
                <FaYoutube className="m-1 text-red-500 hover:text-red-700" />
              </ExternalLink>
            )}
            {twitter && (
              <ExternalLink href={twitter} ariaLabel="Twitter">
                <FaXTwitter className="m-1 text-black hover:text-gray-700" />
              </ExternalLink>
            )}
            {pinterest && (
              <ExternalLink href={pinterest} ariaLabel="Pinterest">
                <FaPinterest className="m-1 text-red-500 hover:text-red-700" />
              </ExternalLink>
            )}
          </div>
        )}
        {website && (
          <div className="">
            <button className="w-48 px-4 py-2 m-2 text-white rounded text-2xl bg-primary hover:bg-dark">
              <ExternalLink href={website}>Our Website</ExternalLink>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactOptions;
