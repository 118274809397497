import React from 'react';
import { useParams } from 'react-router';
import { Footer } from '../layout/Footer';
import { ExtMediaList } from '../pets/ExtMediaList';
import { PetProfileComponent } from '../pets/PetProfileComponent';
import { OrgProfile } from '../support/OrgProfile';
import { client, titleCase } from '../../utils/index';
import Attribution from '../utils/Attribution';
import PageLabel from '../utils/PageLabel';
import { Media } from '../pets/Media';
import LoadingPage from '../utils/LoadingPage';

type PetParams = {
  petId: string;
};

export const PetDetail: React.FC = () => {
  const { petId } = useParams<PetParams>();

  if (!petId) {
    return <div>Unknown Pet</div>;
  }

  const detail = client.pets.getDetail.useQuery({ id: petId });
  const data = detail.data;

  if (!data) {
    return <LoadingPage />;
  }

  const { org, profile } = data;
  const { name, photo } = profile;
  return (
    <div className="flex flex-col min-h-screen">
      <Attribution source={profile.source} />
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-8 pb-2 -mx-4">
          <PageLabel title={titleCase(name) || 'Available'} />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex flex-col md:flex-row md:flex-wrap justify-evenly items-center md:items-start">
            <div className="flex items-center justify-center">
              <Media media={photo} />
            </div>
            <PetProfileComponent profile={profile} />
            <OrgProfile org={org} />
          </div>
          <hr className="hidden md:block my-4 w-3/4 mx-auto border-t-2 border-gray-400" />
          <ExtMediaList profile={profile} />
        </div>
      </div>
      <Footer />
    </div>
  );
};
