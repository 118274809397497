import React, { useCallback, useEffect, useState } from 'react';
import { BlockerFunction, Outlet, useBlocker, useLocation } from 'react-router';
import { HistoryWatcher } from './HistoryWatcher';
import Navbar from '../layout/Navbar';
import { FilterProvider } from '../../state/FilterContext';
import Location from './Location';
import CustomScrollHandler from './CustomScrollHandler';
import { useNavigationContext } from '../../state/NavigationContext';
import classNames from 'classnames';

const Layout: React.FC = () => {
  const { getCallbacks } = useNavigationContext();
  const navActions = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation, historyAction }) => {
      const isReload =
        historyAction === 'REPLACE' &&
        currentLocation.pathname === nextLocation.pathname;
      getCallbacks().forEach((cb) => cb({ isReload }));
      return false; // Allow navigation
    },
    [getCallbacks],
  );

  useBlocker(navActions);

  const location = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const DELAY_PLUS_FADE_TIME_MS = 1500;
  useEffect(() => {
    // Don't bother fading in on error
    if (location.pathname === '/error') {
      setIsFirstLoad(false);
      return;
    }

    // Set a timer to remove the fade-in class after the animation completes
    const timer = setTimeout(() => {
      setIsFirstLoad(false);
    }, DELAY_PLUS_FADE_TIME_MS);

    return () => clearTimeout(timer);
  }, []);

  const isHome = useLocation().pathname === '/';
  const showText = isFirstLoad || isHome;
  const mission = 'Connecting people with locally adoptable pets.';
  return (
    <FilterProvider>
      <HistoryWatcher />
      <div className={classNames('App', { 'bg-black': isFirstLoad })}>
        {showText && (
          <div
            className={classNames(
              'absolute top-1/3 w-full text-center text-white z-10',
              {
                'opacity-1 animate-fadeOut': !isHome,
              },
            )}
          >
            <h1 className="text-4xl">{mission}</h1>
          </div>
        )}
        <div
          className={classNames('bg-white', {
            'opacity-0 animate-fadeIn': isFirstLoad,
          })}
        >
          <Navbar />
          <Location />
          <div className="prp-container">
            <CustomScrollHandler />
            <Outlet />
          </div>
        </div>
      </div>
    </FilterProvider>
  );
};

export default Layout;
