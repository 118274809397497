import { AgeType, Organization, PetType } from '@prp/shared';
import { stopwords } from './stopwords';
import { ErrorInfo } from 'react';

const APP_NAME = 'Pet Rescue+';

export const PET_TYPE_KEY = 'petType';
export const ERROR_DETAILS_KEY = 'errorDetails';

export type ErrorDetails = {
  error: {
    message: string;
    stack: string | undefined;
  };
  errorInfo: ErrorInfo;
};

export const CARD_IMAGE_WIDTH = 300;
export const VERTICAL_IMAGE_THRESHOLD = 1.5;

//TODO is there a better way to get this from tailwind?
export const SECONDARY_COLOR = '#707070';
export const LIGHT_GRAY = '#E0E0E0';

export const noop = () => {
  //make eslint happy
  console.debug('A no-op function');
};

export const getTitle = (pageName?: string) => {
  if (!pageName) {
    return APP_NAME;
  }
  return `${APP_NAME} | ${pageName}`;
};

export { client } from './trpc';

export const formatDate = (isoDate: string) => {
  const date = new Date(isoDate);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}/${day}`;
};

export function titleCase(str: string | undefined): string | null {
  const decodedStr = decodeHtml(str);
  if (!decodedStr) {
    return null;
  }
  return decodedStr.replace(/\w\S*/g, function (txt) {
    // Ensure we do not lowercase after apostrophes and hyphens within words
    const lowerCaseText = txt.toLowerCase();
    if (stopwords.has(lowerCaseText)) {
      return lowerCaseText;
    }
    return (
      lowerCaseText.charAt(0).toUpperCase() +
      lowerCaseText
        .substring(1)
        .replace(/(['-]\w)/g, (match) => match.toUpperCase())
    );
  });
}

export function decodeHtml(html: string | undefined) {
  if (!html) {
    return null;
  }
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export function getDisplayAge(species: PetType, age: AgeType | undefined) {
  const customTerm = species === 'cat' ? 'kitten' : 'puppy';
  return age === 'baby' ? customTerm : age;
}

export function getFirstPhotoUrl(
  organization: Organization,
): string | undefined {
  // Check if 'photos' exists and has at least one element
  if (organization.photos && organization.photos.length > 0) {
    // Access the first element safely and return its 'url'
    return organization.photos?.[0]?.url;
  }

  // Return undefined if 'photos' does not exist or is empty
  return undefined;
}

export function nanoidToNumberInRange(
  nanoid: string,
  min: number,
  max: number,
) {
  // Ensure the nanoid is at least 8 characters long by padding with '0'
  const paddedNanoid = nanoid.padEnd(8, '0');

  // Convert the first part of the padded nanoid to a hexadecimal string
  const hexString = Array.from(new TextEncoder().encode(paddedNanoid))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  const bigint = BigInt('0x' + hexString.substring(0, 8));
  const rangeSize = BigInt(max - min + 1);

  return Number(bigint % rangeSize) + min;
}

export const getDailyCacheBuster = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

// export function getUniqueVisitorId() {
//   let visitorId = localStorage.getItem('visitorId');
//   if (!visitorId) {
//     visitorId = uuidv4();
//     localStorage.setItem('visitorId', visitorId);
//   }
//   return visitorId;
// }

export const photoUrl = (
  url: string,
  mediaType: 'photo' | 'video',
  width = CARD_IMAGE_WIDTH,
) => {
  const urlCard = createSafeURL(url);
  if (!urlCard) {
    return null;
  }
  if (mediaType === 'photo') {
    urlCard.searchParams.append('width', width.toString());
    urlCard.searchParams.append('cb', getDailyCacheBuster());
  }
  return urlCard.toString();
};

export function createSafeURL(url: string, base?: string): URL | null {
  try {
    return new URL(url, base);
  } catch (error) {
    console.error('Invalid URL:', error);
    return null; // Return null or handle the error as needed
  }
}

export const formatToLocalTime = (dateString: string): string => {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Format the date to local time
  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const cleanText = (input: string): string => {
  return input
    .replace(/[\u2019\u2018\uFFFD\u0019]/g, "'") // Replace smart quotes and replacement characters
    .replace(/[\x00-\x09\x0B-\x1F\x7F]/g, ''); // Remove other control characters except \n
};

