import { boundedNormalDistValue } from '../../utils/naturalDistribution';
import React from 'react';
import OrgIcon from './OrgIcon';
import { Link } from 'react-router';

export interface OrgStickerProps {
  orgName: string;
  orgId: string;
  petId: string;
  id: string;
}

const OrgSticker: React.FC<OrgStickerProps> = ({
  petId,
  id,
  orgName,
  orgId,
}) => {
  const photoId = id || petId;
  const rotation = boundedNormalDistValue(photoId, -75, 75);

  const bottom = boundedNormalDistValue(photoId, 15, 30);
  const horizontalPx = boundedNormalDistValue(photoId, -3, 5);
  const side = boundedNormalDistValue(photoId, 0, 100);
  const horizontal =
    side < 80
      ? {
          left: `${horizontalPx}px`,
        }
      : {
          right: `${horizontalPx}px`,
        };

  const style: React.CSSProperties = {
    position: 'absolute',
    zIndex: 5, // Use camelCase for zIndex
    bottom: `${bottom}%`, // Percentage values should be strings
    ...horizontal,
    overflow: 'hidden',
    borderRadius: '50%',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.15)', // Use camelCase for boxShadow
  };

  const rotationStyle: React.CSSProperties = {
    transform: `rotate(${rotation}deg)`,
  };

  const overlayStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    background: `
      linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 60%),
      linear-gradient(to bottom, rgba(211, 211, 211, 0.1), rgba(240, 240, 240, 0.1))
    `,
    pointerEvents: 'none', // Ensures the overlay does not block interactions
  };

  return (
    <div style={style}>
      <div style={rotationStyle}>
        <Link to={`/org/detail/${orgId}`}>
          <OrgIcon orgName={orgName} orgId={orgId} />
        </Link>
      </div>
      <div style={overlayStyle} />
    </div>
  );
};

export default OrgSticker;
