import React from 'react';
import { OrgSource } from '@prp/shared';
import ExternalLink from '../utils/ExternalLink';
import ExpandableText from '../utils/ExpandableText';

interface PetDescriptionProps {
  source: OrgSource;
  bio?: string;
  externalUrl?: string;
}

const PetDescription: React.FC<PetDescriptionProps> = ({
  source,
  bio,
  externalUrl,
}) => {
  if (source === 'PetFinder') {
    if (!externalUrl) {
      return null; //this isn't expected
    }
    return (
      <ExternalLink href={externalUrl} ariaLabel={'petfinder profile'}>
        <button className="px-4 py-2 m-2 text-white rounded text-xl bg-primary hover:bg-dark">
          View on Petfinder
        </button>
      </ExternalLink>
    );
  }

  return <div>{bio && <ExpandableText paragraphs={[bio]} previewHeight={'medium'} />}</div>;
};

export default PetDescription;
