import React, { useContext, useRef } from 'react';
import MapComponent from '../utils/MapComponent';
import { LocationContext } from '../../state/LocationContext';
import { useInfiniteScrollQuery } from '../../hooks/useInfiniteScrollQuery';
import { client, getTitle, PET_TYPE_KEY } from '../../utils/index';
import { useLocation } from 'react-router';
import { MOHAWK_HUDSON } from '../utils/Location';
import LoadingState from '../layout/LoadingState';
import { useLocalStorage } from 'usehooks-ts';
import { OrganizationSummary, PetType } from '@prp/shared';
import PetTypeSelector from '../utils/PetTypeSelector';
import OrganizationRow from '../support/OrganizationRow';
import { Footer } from '../layout/Footer';
import { getAcronym, getBackgroundColor } from '../support/OrgIcon';

const Organizations: React.FC = () => {
  const { location } = useContext(LocationContext);
  const knownLocation = location || MOHAWK_HUDSON;
  const [petType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'dog');

  const [selectedOrg, setSelectedOrg] = React.useState<string | null>(null);

  const handleOrgClick = (orgId: string) => {
    setSelectedOrg(orgId);
  };

  const scrollableRef = useRef(null);
  const { data, isLoading, isFetchingNextPage } = useInfiniteScrollQuery<{
    items: OrganizationSummary[];
    nextCursor: string | undefined;
  }>(
    client.org.getByLocation.useInfiniteQuery(
      {
        location: knownLocation,
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
      },
    ),
    useLocation().pathname,
    scrollableRef.current ? scrollableRef.current : undefined,
  );

  document.title = getTitle('Organizations');
  const orgs: OrganizationSummary[] =
    data?.pages.flatMap((page) => page.items) || [];

  const orgMarkers = orgs.map((org) => {
    const hasNoPetsOfType = petType === 'dog' ? !org.dogs : !org.cats;
    return {
      id: org.id,
      lat: org.location.latitude,
      lng: org.location.longitude,
      color: getBackgroundColor(org.id),
      text: getAcronym(org.name),
      isDisabled: hasNoPetsOfType,
    };
  });

  return (
    <div>
      <div className="pt-12 px-4">
        <PetTypeSelector />
        <div className="md:flex md:items-center p-4">
          <div className="flex-1 m-1">
            <MapComponent
              markers={orgMarkers}
              selected={selectedOrg}
              handleClick={handleOrgClick}
            />
          </div>
          <div
            ref={scrollableRef}
            style={{ height: 'calc(100vh - 5rem)' }}
            className="flex-1 flex flex-col m-1 overflow-y-auto p-4 border rounded-lg"
          >
            {orgs.map((org) => {
              const isSelected = selectedOrg === org.id;
              return (
                <OrganizationRow
                  key={org.id}
                  org={org}
                  isSelected={isSelected}
                  petType={petType}
                  handleClick={handleOrgClick}
                />
              );
            })}
            <div className="flex-grow flex items-center justify-center">
              {(isLoading || isFetchingNextPage) && <LoadingState />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Organizations;
