import React from 'react';
import { client } from '../../utils/index';
import { PetType, PhotoCardMedia, SearchFilter } from '@prp/shared';
import { useInfiniteScrollQuery } from '../../hooks/useInfiniteScrollQuery';
import { useLocation } from 'react-router';
import Gallery from '../Gallery';

export interface PetListProps {
  orgId: string;
  petType: PetType;
  filter: SearchFilter;
}

const PetList: React.FC<PetListProps> = ({ orgId, petType, filter }) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage } =
    useInfiniteScrollQuery<{
      items: PhotoCardMedia[];
      nextCursor: string | undefined;
    }>(
      client.pets.getAvailableByOrg.useInfiniteQuery(
        {
          id: orgId,
          petType,
          filter,
        },
        {
          getNextPageParam: (lastPage) => lastPage.nextCursor,
        },
      ),
      useLocation().pathname,
    );

  const media = data?.pages.map((group) => group.items).flat() || [];
  return (
    <div className="px-4">
      <Gallery
        media={media}
        isLoading={isLoading || isFetchingNextPage}
        hasNextPage={hasNextPage}
        fadeIn={false}
        showOrgSticker={false}
      />
    </div>
  );
};

export default PetList;
